import React from "react";
import { Accordion } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../utils/strings";
import { MichelinRatings } from "../../utils/constants";

interface Props {
  displayablePrefectures: string[];
  displayableCategories: {};
  availableLocales: string[];
}

const GlobalNavigation: React.FC<Props> = ({
  displayablePrefectures,
  displayableCategories,
  availableLocales,
}) => {
  console.log(availableLocales);
  return (
    <>
      <Accordion
        defaultActiveKey={["1"]}
        alwaysOpen
        className="l-spMenu_accordion"
      >
        <Accordion.Item eventKey="1" className="l-spMenu_accordion_item">
          <Accordion.Header as="div">
            <i className="fas fa-map-marker-alt"></i>
            {window.i18n.t(
              "components.restaurants.global_navigation.spmenu_item.areas.heading",
            )}
          </Accordion.Header>
          <Accordion.Body className="l-spMenu_accordion_body">
            <ul>
              {displayablePrefectures.map((prefecture) => {
                return (
                  <li key={prefecture}>
                    <a
                      href={`${window.i18n.locale_path}/restaurants/pref/${prefecture}`}
                    >
                      {window.i18n.t(`prefectures.${prefecture}`)}
                    </a>
                  </li>
                );
              })}
              <li>
                <a href={`/restaurants`}>
                  {window.i18n.t(
                    "components.restaurants.global_navigation.spmenu_item.areas.link_text",
                  )}
                </a>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="l-spMenu_accordion_item">
          <Accordion.Header as="div">
            <i className="fas fa-utensils"></i>
            {window.i18n.t(
              "components.restaurants.global_navigation.spmenu_item.categories.heading",
            )}
          </Accordion.Header>
          <Accordion.Body className="l-spMenu_accordion_body">
            <ul>
              {Object.keys(displayableCategories).map((category) => {
                return (
                  <li key={category}>
                    <a
                      href={`${window.i18n.locale_path}/restaurants/categories/${category}`}
                    >
                      {capitalizeFirstLetter(displayableCategories[category])}
                    </a>
                  </li>
                );
              })}
              <li>
                <a href={`/restaurants/categories`}>
                  {window.i18n.t(
                    "components.restaurants.global_navigation.spmenu_item.categories.link_text",
                  )}
                </a>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="l-spMenu_accordion_item">
          <Accordion.Header as="div">
            <i className="far fa-star"></i>
            {window.i18n.t(
              "components.restaurants.global_navigation.spmenu_item.michelin_stars.heading",
            )}
          </Accordion.Header>
          <Accordion.Body className="l-spMenu_accordion_body">
            <ul>
              {Object.keys(MichelinRatings).map((rating) => {
                return (
                  <li key={rating}>
                    <a
                      href={`${window.i18n.locale_path}/restaurants/michelin/${rating}`}
                    >
                      {window.i18n.t(`michelin_stars.${rating}`)}
                    </a>
                  </li>
                );
              })}
              <li>
                <a href={`/restaurants/michelin`}>
                  {window.i18n.t(
                    "components.restaurants.global_navigation.spmenu_item.michelin_stars.link_text",
                  )}
                </a>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="l-spMenu_nav navbar-nav">
        <a href={`/etiquette`} className="nav-link">
          <i className="far fa-handshake"></i>
          {window.i18n.t(
            "components.restaurants.global_navigation.spmenu_item.etiquette.link_text",
          )}
        </a>
        <a
          href={`https://je-omakase.zendesk.com/hc/en-us`}
          target={"_blank"}
          className="nav-link"
          rel="noreferrer"
        >
          <i className="far fa-question-circle"></i>
          {window.i18n.t(
            "components.restaurants.global_navigation.spmenu_item.faq.link_text",
          )}
        </a>
        {/* <a href={`/restaurant_owners`}>店舗様、事業者様へ</a> */}
      </div>
    </>
  );
};

export default GlobalNavigation;
