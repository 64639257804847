import React from "react";

import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

interface ImageData {
  url: string;
  alt_text: string | null;
  sort_number: number | null;
}

interface Props {
  images: ImageData[];
}

const RestaurantImagesSlider: React.FC<Props> = ({ images }) => {
  const sortedImages = images.slice().sort((a, b) => {
    return (a.sort_number || 0) - (b.sort_number || 0);
  });

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      navigation
      loop={true}
      modules={[Pagination, Navigation]}
      style={{ marginBottom: "40px" }}
    >
      {sortedImages.map((image, index) => (
        <SwiperSlide key={index}>
          <img
            src={image.url}
            alt={image.alt_text || `Restaurant Image ${index + 1}`}
            loading="lazy"
            style={{ width: "100%", height: "auto" }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default RestaurantImagesSlider;
