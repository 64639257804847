import React from "react";
import { Navbar, Nav, Offcanvas } from "react-bootstrap";
import GlobalNavigation from "./global_navigation";

interface UserProfile {
  fullName: string;
  phoneFullNumber: string;
}

interface Props {
  isSignedIn: boolean;
  user?: object;
  userProfile?: UserProfile;
  displayablePrefectures: string[];
  displayableCategories: {};
  availableLocales: string[];
}

const NavigationBar: React.FC<Props> = ({
  isSignedIn,
  user,
  userProfile,
  displayablePrefectures,
  displayableCategories,
  availableLocales,
}) => {
  return (
    <Navbar as="div" expand="md" className="custom-nav-bar justify-content-end">
      <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
      <Navbar.Offcanvas
        id={`offcanvasNavbar-expand-md`}
        aria-labelledby={`offcanvasNavbarLabel-expand-md`}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title
            id={`offcanvasNavbarLabel-expand-md`}
          ></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {isSignedIn ? (
            <>
              <div className="l-spMenu_profile">
                <div className="l-spMenu_profile_name">
                  {userProfile.fullName}
                </div>
                <div className="l-spMenu_profile_email">{user.email}</div>
                <div className="l-spMenu_profile_tel">
                  {userProfile.phoneFullNumber}
                </div>
              </div>
              <Nav className="l-spMenu_nav">
                <Nav.Link href={`${window.i18n.locale_path}/user/reservations`}>
                  <i className="fas fa-fw fa-concierge-bell"></i>
                  <span>
                    {window.i18n.t(
                      "components.restaurants.header_nav_bar.spmenu.reservations",
                    )}
                  </span>
                </Nav.Link>
                <Nav.Link
                  href={`${window.i18n.locale_path}/user/favorite_restaurants`}
                >
                  <i className="far fa-fw fa-heart"></i>
                  <span>
                    {window.i18n.t(
                      "components.restaurants.header_nav_bar.spmenu.favorites",
                    )}
                  </span>
                </Nav.Link>
                <Nav.Link href={`${window.i18n.locale_path}/user/accounts`}>
                  <i className="fas fa-fw fa-user"></i>
                  <span>
                    {window.i18n.t(
                      "components.restaurants.header_nav_bar.spmenu.accounts",
                    )}
                  </span>
                </Nav.Link>
                <Nav.Link
                  href={`${window.i18n.locale_path}/user/payment_method`}
                >
                  <i className="fas fa-fw fa-credit-card"></i>
                  <span>
                    {window.i18n.t(
                      "components.restaurants.header_nav_bar.spmenu.payment_methods",
                    )}
                  </span>
                </Nav.Link>
              </Nav>
            </>
          ) : (
            <Nav className="l-spMenu_nav l-spMenu_nav_account">
              <Nav.Link href={`${window.i18n.locale_path}/users/sign_in`}>
                <i className="fas fa-fw fa-user-plus"></i>
                <span>
                  {window.i18n.t(
                    "components.restaurants.header_nav_bar.spmenu.sign_up",
                  )}
                </span>
              </Nav.Link>
              <Nav.Link href={`${window.i18n.locale_path}/user/auth/authorize`}>
                <i className="fas fa-fw fa-sign-in-alt"></i>
                <span>
                  {window.i18n.t(
                    "components.restaurants.header_nav_bar.spmenu.log_in",
                  )}
                </span>
              </Nav.Link>
            </Nav>
          )}
          <GlobalNavigation
            displayablePrefectures={displayablePrefectures}
            displayableCategories={displayableCategories}
            availableLocales={availableLocales}
          />
          {isSignedIn && (
            // ログアウトだけは最下部に表示
            <Nav className="l-spMenu_nav">
              <Nav.Item>
                <form
                  method="delete"
                  action={`${window.i18n.locale_path}/users/sign_out`}
                >
                  <button>
                    <i className="fas fa-fw fa-sign-out-alt"></i>
                    <span>
                      {window.i18n.t(
                        "components.restaurants.header_nav_bar.spmenu.log_out",
                      )}
                    </span>
                  </button>
                </form>
              </Nav.Item>
            </Nav>
          )}
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Navbar>
  );
};

export default NavigationBar;
