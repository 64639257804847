import React, { Fragment, useEffect, useRef, useState } from "react";
import { bpMobile } from "../../utils/constants";

type Props = {
  comment: string;
};

const RestaurantReviewContent: React.FC<Props> = ({ comment }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const lines = comment ? comment.split("\n") : [];

  // 各レビューの初期表示の高さ(端末により変える)
  // PCの場合は100px、SPの場合は120px
  // NOTE:
  // _articles.scssのp-articleShow_reviews_contentのmax-heightの設定値と合わせること
  const heightThreshold = window.innerWidth > bpMobile ? 100 : 120;

  useEffect(() => {
    const container = containerRef.current!;

    if (container.offsetHeight < heightThreshold) {
      setIsOpen(true);
    }
  }, []);

  return (
    <div
      ref={containerRef}
      className={`p-articleShow_reviews_content ${isOpen ? "open" : ""}`}
    >
      {lines.map((line, index) => (
        <Fragment key={index}>
          {index > 0 && <br />}
          {line}
        </Fragment>
      ))}
      <div className="p-articleShow_reviews_show_more">
        <button
          className="btn btn-sm btn-secondary rounded-pill"
          onClick={() => setIsOpen(true)}
        >
          Show more
        </button>
      </div>
    </div>
  );
};

export default RestaurantReviewContent;
