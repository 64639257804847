import { format as dateFnsFormat } from "date-fns";

const format = new RegExp("[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]");

function newDateFromDateString(dateString: string) {
  const date = new Date(dateString);
  if (!dateString.match(format) || isNaN(date.getTime())) {
    throw new Error("Could not parse date string");
  }
  const offset = date.getTimezoneOffset() * 60 * 1000;
  return new Date(date.getTime() + offset);
}

function toISODateString(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function getDatesBetween(startDate: Date, endDate: Date): Date[] {
  const dates: Date[] = [];
  startDate = new Date(toISODateString(startDate));
  endDate = new Date(toISODateString(endDate));
  const currentDate: Date = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

function toJstIsoDateString(date: Date | undefined): string {
  if (!date) return "";
  const jstDate = toJstIsoDate(date);
  return toISODateString(jstDate);
}

function toJstIsoDate(date: Date | undefined): Date {
  // localタイムのUTCからの差分を取得した上でJSTに変換する
  const localOffset = (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000;
  // getTime()はUTCなので+900してJSTに変換する
  const targetDateTime = date.getTime() + 9 * 60 * 60 * 1000;
  return new Date(targetDateTime + localOffset);
}

function getDayOfTheWeek(date: Date | undefined, locale: string): string {
  let daysOfTheWeek = [];
  switch (locale) {
    case "ja":
      daysOfTheWeek = ["日", "月", "火", "水", "木", "金", "土"];
      break;
    default: // 一旦日本語以外は英語にする
      daysOfTheWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
  }
  return daysOfTheWeek[date.getDay()];
}

/**
 * 2つの日付の日数を計算
 */
const calcDaysBetween2days = (from: Date, to: Date) => {
  return (to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24);
};

/**
 * 24時間表記からAM、PM表記に直した値を返す
 */
const convertToAmPm = (timeLabel: string, locale: Locale): string => {
  const timeParts = timeLabel.split(":");

  // 形式が不正、または時間・分が数値に変換できない場合、timeLabelを返す
  if (
    timeParts.length !== 2 ||
    isNaN(Number(timeParts[0])) ||
    isNaN(Number(timeParts[1]))
  ) {
    return timeLabel;
  }

  const [hours, minutes] = timeParts.map(Number);

  // Dateオブジェクトに変換
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  return dateFnsFormat(date, "a h:mm", { locale });
};

export {
  getDatesBetween,
  toISODateString,
  newDateFromDateString,
  toJstIsoDate,
  toJstIsoDateString,
  getDayOfTheWeek,
  calcDaysBetween2days,
  convertToAmPm,
};
