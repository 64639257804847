import { enUS, ja, ko, zhCN, zhTW } from "date-fns/locale";

const getDateFnsLocale = () => {
  const path = window.location.pathname;
  if (path.startsWith("/ko")) return ko;
  if (path.startsWith("/zh-cn")) return zhCN;
  if (path.startsWith("/zh-tw")) return zhTW;
  if (path.startsWith("/ja")) return ja;
  return enUS; // default locale
};

export { getDateFnsLocale };
