import React from "react";
import { formControlContext, FormFieldState } from "./form_control_context";
import { PartnerProps } from "./form_group";

type Props = {
  text: string;
  className: string;
  partner: PartnerProps | null;
  isTemporaryReservation: boolean;
  guestCount: number;
};

const SubmitButton: React.FC<Props> = ({
  text,
  className,
  partner,
  isTemporaryReservation,
  guestCount,
}) => {
  const context: FormFieldState = React.useContext(formControlContext);
  const disabledClass = className + " disabled";

  return (
    <>
      <button
        type="submit"
        name="commit"
        className={context.isFormSending ? disabledClass : className}
        disabled={
          context.dietaryRestrictionsAnyCheckedGuests.length +
            context.dietaryRestrictionsNoRestrictionGuests.length !==
            guestCount ||
          (!context.isReadyStripeCard && (partner?.balance ?? 0) <= 0) ||
          ((context.isDietaryRestrictionsSelectVegan ||
            context.isDietaryRestrictionsSelectOther) &&
            !context.isSpecialRequestPresent) ||
          !context.isRequestChecked ||
          ((isTemporaryReservation || !!partner) &&
            !context.isUserProfileEntered)
        }
        onClick={() => context.setIsFormSending(true)}
      >
        {context.isFormSending && (
          <span className="spinner-border spinner-border-sm" />
        )}
        {context.isFormSending ? ` ${text}` : text}
      </button>
    </>
  );
};

export default SubmitButton;
