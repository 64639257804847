import React, { useState } from "react";
import { InputImage } from "./InputImage";

type Props = {
  accept: string;
  imageCount: number;
};

const UploadImageField: React.FC<Props> = ({ accept, imageCount }) => {
  const [fields, setFields] = useState([{ value: "" }]);

  const handleFileUpload = () => {
    if (fields.length < imageCount) {
      setFields([...fields, { value: "" }]);
    }
  };

  return (
    <>
      {fields.map((_field, index) => (
        <div key={index}>
          <label className="form-label">
            <b>
              {window.i18n.t(
                "components.restaurants.reviews.upload_image.label",
              )}
              {` ${index + 1}`}
            </b>
          </label>
          <InputImage
            accept={accept}
            name={`restaurant_review[image_${index + 1}]`}
            id={`restaurant_review_image_${index + 1}`}
            handleFileUpload={handleFileUpload}
          />
        </div>
      ))}
    </>
  );
};

export default UploadImageField;
