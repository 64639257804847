import * as React from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import Rails from "rails-ujs";

function CookieStorageAgreementPopup() {
  const [show, setShow] = React.useState(true);

  const handleClick = React.useCallback(() => {
    setShow(false);

    const url = "/api/public/v1/user/cookie_storage_agreements";
    axios
      .post(url, null, {
        headers: { "X-CSRF-Token": Rails.csrfToken() },
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div
      style={{ display: show ? "block" : "none" }}
      className="c-cookieAgreement"
    >
      <div className="c-cookieAgreement_content">
        <p className="m-0">
          {window.i18n.t("cookie_storage_agreed_modal.info")}
        </p>
        <Button onClick={handleClick}>
          {window.i18n.t("cookie_storage_agreed_modal.agree")}
        </Button>
      </div>
    </div>
  );
}

export default CookieStorageAgreementPopup;
