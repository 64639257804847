import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { bpMobile } from "../utils/constants";

export type MakerLatLng = {
  restaurantId: number;
  slug: string;
  restaurantName: string;
  lat: number;
  lng: number;
  operationHours: string;
  holidays: string;
};

interface Props {
  latLangList: MakerLatLng[];
  makerClicked: (restaurantId: number | string) => void;
  mapKey: string;
  showDataWithoutHover: boolean;
}

/**
 * マップ表示コンポーネント
 */
export const MakerMap: React.FC<Props> = ({
  latLangList,
  makerClicked,
  mapKey,
  showDataWithoutHover,
}) => {
  const [hoveredMarkerId, setHoveredMarkerId] = useState(null);
  const [center, setCenter] = useState(null);
  const [makers, setMakers] = useState([]);
  const isPc = window.innerWidth > bpMobile;

  /**
   * マウント時処理
   */
  useEffect(() => {
    const newMakers = [];

    if (latLangList.length === 0) {
      return;
    }

    // マーカー情報をセット
    // この情報をマーカーをホバリングした時に表示する
    latLangList.forEach((latLang, index) => {
      newMakers.push({
        id: index,
        lat: latLang.lat,
        lng: latLang.lng,
        slug: latLang.slug,
        restaurantId: latLang.restaurantId,
        restaurantName: latLang.restaurantName,
        holidays: latLang.holidays,
        operationHours: latLang.operationHours,
      });
    });
    setMakers(newMakers);
    setCenter(calculateCenter(newMakers));
  }, []);

  /**
   * 指定された井戸経度の中心を計算
   */
  const calculateCenter = (markers) => {
    const latList = markers.map((marker) => marker.lat);
    const lngList = markers.map((marker) => marker.lng);
    const lat = (Math.max(...latList) + Math.min(...latList)) / 2;
    const lng = (Math.max(...lngList) + Math.min(...lngList)) / 2;
    return { lat: lat, lng: lng };
  };

  const MarkerComponent = ({
    id,
    restaurantId,
    slug,
    showText,
    restaurantName,
    holidays,
    operationHours,
  }) => (
    <div
      className={"p-articleShow_map_marker"}
      onClick={() =>
        restaurantId
          ? handleRestaurantIdClick(restaurantId)
          : handleSlugClick(slug)
      }
      onMouseEnter={() => setHoveredMarker(id)}
      onMouseLeave={() => setHoveredMarker(null)}
    >
      <i className="fa-solid fa-location-dot"></i>
      <div className={"p-articleShow_map_marker_drop"}></div>
      {showText && (
        <div className={"p-articleShow_map_marker_text"}>
          <div className={"p-articleShow_map_marker_title"}>
            {restaurantName}
          </div>
          {isPc && (
            <div className={"p-articleShow_map_marker_holiday"}>
              {window.i18n.t("common.maker_map.holidays")}: {holidays || "-"}
            </div>
          )}
          {isPc && (
            <div className={"p-articleShow_map_marker_desc"}>
              {window.i18n.t("common.maker_map.operation_hours")}:{" "}
              {operationHours || "-"}
            </div>
          )}
        </div>
      )}
    </div>
  );

  /**
   * マーカークリック時処理
   * 記事のIDまで移動
   */
  const handleRestaurantIdClick = (restaurantId: number) => {
    const restaurantInfoForId = document.getElementById(
      `restaurant-id-${restaurantId}`,
    );
    if (restaurantInfoForId) {
      restaurantInfoForId.scrollIntoView({ behavior: "smooth" });
    }
    if (makerClicked) {
      makerClicked(restaurantId);
    }
  };

  /**
   * 外部店舗のマーカークリック時処理
   * 記事のIDまで移動
   */
  const handleSlugClick = (slug: string) => {
    const restaurantInfoForSlug = document.getElementById(
      `restaurant-info-${slug}`,
    );
    if (restaurantInfoForSlug) {
      restaurantInfoForSlug.scrollIntoView({ behavior: "smooth" });
    }
    if (makerClicked) {
      makerClicked(slug);
    }
  };

  /**
   * マーカーホバー時処理
   */
  const setHoveredMarker = (id: string) => {
    setHoveredMarkerId(id);
  };

  /**
   * 表示/非表示判定
   */
  const ShowText = (makerId: number) => {
    return showDataWithoutHover || hoveredMarkerId === makerId;
  };

  return (
    <>
      <div className={`p-articleShow_mapComponent${isPc ? "PC" : "SP"}`}>
        {mapKey && center && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: mapKey }}
            defaultCenter={center}
            defaultZoom={window.innerWidth > bpMobile ? 14 : 12}
          >
            {makers.map((maker) => {
              return (
                <MarkerComponent
                  key={maker.id}
                  lat={maker.lat}
                  lng={maker.lng}
                  id={maker.id}
                  slug={maker.slug}
                  restaurantId={maker.restaurantId}
                  showText={ShowText(maker.id)}
                  restaurantName={maker.restaurantName}
                  holidays={maker.holidays}
                  operationHours={maker.operationHours}
                />
              );
            })}
          </GoogleMapReact>
        )}
      </div>
    </>
  );
};

export default MakerMap;
