import React, { useMemo } from "react";
import uniq from "lodash/uniq";

import { Option, Value } from "./dietary_restriction_field";
import { FormFieldState, formControlContext } from "./form_control_context";

type Props = {
  options: Option[];
  moreOptions: Option[];
  moreLabel: string;
  values: Value[];
  guestNo: number;
  onChange: (values: Value[]) => void;
};

const DietaryRestrictionEditor: React.FC<Props> = ({
  options,
  moreOptions,
  moreLabel,
  values,
  guestNo,
  onChange,
}) => {
  const context: FormFieldState = React.useContext(formControlContext);
  const [openMore, setOpenMore] = React.useState(() =>
    moreOptions.some(([value]) => values.includes(value)),
  );

  const isNoRestrictions = useMemo(
    () => context.dietaryRestrictionsNoRestrictionGuests.includes(guestNo),
    [context.dietaryRestrictionsNoRestrictionGuests],
  );

  const handleChange = (value: Value, checked: boolean) => {
    if (context.dietaryRestrictionsNoRestrictionGuests.includes(guestNo)) {
      context.setDietaryRestrictionsNoRestrictionGuests((guestNos) =>
        guestNos.filter((no) => no !== guestNo),
      );
    }

    const newValues = checked
      ? [...values, value]
      : values.filter((v) => v !== value);

    if (newValues.length === 0) {
      context.setDietaryRestrictionsAnyCheckedGuests((guestNos) =>
        guestNos.filter((no) => no !== guestNo),
      );
    } else {
      context.setDietaryRestrictionsAnyCheckedGuests((guestNos) =>
        uniq([...guestNos, guestNo]),
      );
    }

    onChange(newValues);
  };

  return (
    <div className="c-multipleCheck_content">
      <div className="c-multipleCheck_none">
        <div className="c-easyCheck">
          <label>
            <input
              type="checkbox"
              checked={isNoRestrictions}
              onChange={(e) => {
                if (e.target.checked) {
                  context.setDietaryRestrictionsAnyCheckedGuests((guestNos) =>
                    guestNos.filter((no) => no !== guestNo),
                  );
                  context.setDietaryRestrictionsNoRestrictionGuests(
                    (guestNos) => uniq([...guestNos, guestNo]),
                  );
                  onChange([]);
                }
              }}
            />
            <div className="c-easyCheck_content">
              <div className="ui label mini usergroup">
                {window.i18n.t(
                  "components.user.new_reservation_requests.dietary_restriction_editor.easycheck.label",
                )}
              </div>
            </div>
          </label>
        </div>
      </div>
      <div className="c-multipleCheck_select">
        <ul>
          {options.map(([value, label]) => (
            <li key={value}>
              <div className="c-easyCheck">
                <label>
                  <input
                    type="checkbox"
                    checked={values.includes(value)}
                    onChange={(e) => handleChange(value, e.target.checked)}
                  />
                  <div className="c-easyCheck_content">
                    <div className="ui label mini usergroup">{label}</div>
                  </div>
                </label>
              </div>
            </li>
          ))}
          {!openMore && (
            <li onClick={() => setOpenMore(true)}>
              <div className="c-easyCheck">
                <label>
                  <div className="c-easyCheck_content">
                    <div className="ui label mini usergroup">{moreLabel}</div>
                  </div>
                </label>
              </div>
            </li>
          )}
        </ul>
      </div>
      {openMore && (
        <div className="c-multipleCheck_select">
          <ul>
            {moreOptions.map(([value, label]) => (
              <li key={value}>
                <div className="c-easyCheck">
                  <label>
                    <input
                      type="checkbox"
                      checked={values.includes(value)}
                      onChange={(e) => handleChange(value, e.target.checked)}
                    />
                    <div className="c-easyCheck_content">
                      <div className="ui label mini usergroup">{label}</div>
                    </div>
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DietaryRestrictionEditor;
