type AnyObject = { [key: string]: unknown };

export const deepCamelToSnake = (
  obj: AnyObject | AnyObject[],
): AnyObject | AnyObject[] => {
  if (Array.isArray(obj)) {
    return obj.map((item) => deepCamelToSnake(item as AnyObject));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc: AnyObject, key: string) => {
      const snakeKey = key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
      acc[snakeKey] = deepCamelToSnake(obj[key] as AnyObject);
      return acc;
    }, {});
  }
  return obj;
};

export const deepSnakeToCamel = (
  obj: AnyObject | AnyObject[],
): AnyObject | AnyObject[] => {
  if (Array.isArray(obj)) {
    return obj.map((item) => deepSnakeToCamel(item));
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc: AnyObject, key: string) => {
      const camelKey = key
        .toLowerCase()
        .replace(/_./g, (match) => match.charAt(1).toUpperCase());
      acc[camelKey] = deepSnakeToCamel(obj[key] as AnyObject);
      return acc;
    }, {});
  }
  return obj;
};
