import React, { useState } from "react";
import Rails from "rails-ujs";
import axios from "axios";

import { Button, Form, Modal } from "react-bootstrap";

import FlashMessage from "../../components/FlashMessage";

type FlashMessageProps = {
  type: "notice" | "alert";
  message: string;
};

type Props = {
  beforeEmail: string;
  reservationUid: number;
};

const UnsignedinReservationAlert: React.FC<Props> = ({
  beforeEmail,
  reservationUid,
}) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [flashMessage, setFlashMessage] = useState<FlashMessageProps>({
    type: "notice",
    message: "",
  });
  const [email, setEmail] = useState("");

  const handleClickUpdateEmail = () => {
    setLoading(true);

    try {
      axios
        .put(`/user/reservations/${reservationUid}/reservation_profile`, {
          authenticity_token: Rails.csrfToken(),
          email: email,
        })
        .then((response) => {
          if (response.status === 200) {
            setShowModal(false);
            setFlashMessage({ type: "notice", message: response.data.message });

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        })
        .catch((error) => {
          setShowModal(false);
          setFlashMessage({
            type: "alert",
            message: error.response.data.message,
          });
        });
    } catch (error) {
      setFlashMessage({ type: "alert", message: error.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {flashMessage.message && (
        <FlashMessage type={flashMessage.type} message={flashMessage.message} />
      )}
      <div className="alert alert-warning">
        <div className="hstack gap-1">
          {window.i18n.t(
            "components.user.instant_reservations.complete.default.alert_html",
          )}
          <b>{beforeEmail}.</b>
          <Button
            className="btn-sm"
            variant="link"
            onClick={() => setShowModal(true)}
          >
            {window.i18n.t(
              "components.user.instant_reservations.complete.default.resend_email",
            )}
          </Button>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {window.i18n.t(
              "components.user.instant_reservations.complete.default.resend_email",
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {window.i18n.t(
              "components.user.instant_reservations.complete.default.if_no_email_received_enter_correct_address",
            )}
          </p>
          <Form>
            <Form.Group className="mb-3" controlId="email">
              <Form.Control
                className="mb-2"
                type="email"
                placeholder="your@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Text className="text-muted">
                {window.i18n.t(
                  "components.user.instant_reservations.complete.default.if_no_email_received",
                )}
                <a href="https://je-omakase.zendesk.com/hc/en-us/articles/6983620306831-Contact-us-here">
                  {window.i18n.t(
                    "components.user.instant_reservations.complete.default.contact_us_here",
                  )}
                </a>
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            disabled={loading}
            onClick={() => setShowModal(false)}
          >
            {window.i18n.t(
              "components.user.instant_reservations.complete.default.cancel_button_text",
            )}
          </Button>
          <Button
            variant="outline-primary"
            onClick={handleClickUpdateEmail}
            disabled={loading}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2" />
            )}
            {window.i18n.t(
              "components.user.instant_reservations.complete.default.update_button_text",
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UnsignedinReservationAlert;
