import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  accept: string;
  name: string;
  id: string;
  handleFileUpload: () => void;
};

export const InputImage: React.FC<Props> = ({
  accept,
  name,
  id,
  handleFileUpload,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget.files[0]) {
      const targetFile = e.currentTarget.files[0];
      setImageFile(targetFile);
      setImageUrl(window.URL.createObjectURL(targetFile));
      handleFileUpload();
    }
  };

  return (
    <div className="p-rRev_review_uploadImage">
      <label htmlFor={id}>
        {!!imageFile && !!imageUrl ? (
          <img src={imageUrl} width={"90%"} alt={imageFile.name} />
        ) : (
          <>
            <FontAwesomeIcon
              icon="image"
              className="p-rRev_img_default"
              width={50}
            />
            <p className="mb-0">
              {window.i18n.t(
                "components.restaurants.reviews.upload_image.field_label",
              )}
            </p>
          </>
        )}

        <input
          id={id}
          name={name}
          ref={fileInputRef}
          type="file"
          accept={accept}
          className="form-control"
          onChange={handleFileChange}
          hidden
        />
      </label>
    </div>
  );
};
