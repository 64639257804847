import Rails from "rails-ujs";

export const axiosHeader = () => {
  return {
    "X-CSRF-Token": Rails.csrfToken(),
    "Content-Type": "application/json",
    Accept: "application/json",
  };
};

export const axiosConfig = {
  headers: axiosHeader(),
};
