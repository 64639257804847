/* eslint no-console:0 */
/**
 * ユーザー画面向けJavaScriptファイル
 * ユーザー画面で利用
 *  取り込み対象JSファイルは、
 *  ./components以下です。
 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)

import "@hotwired/turbo-rails";
import "bootstrap";
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faUtensils } from "@fortawesome/free-solid-svg-icons/faUtensils";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons/faLocationDot";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons/faUserGroup";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons/faArrowCircleRight";
import { faMedal } from "@fortawesome/free-solid-svg-icons/faMedal";
import { faWallet } from "@fortawesome/free-solid-svg-icons/faWallet";
import { faLanguage } from "@fortawesome/free-solid-svg-icons/faLanguage";
import { faLeaf } from "@fortawesome/free-solid-svg-icons/faLeaf";
import { faWifi } from "@fortawesome/free-solid-svg-icons/faWifi";
import { faBaby } from "@fortawesome/free-solid-svg-icons/faBaby";
import { faWineBottle } from "@fortawesome/free-solid-svg-icons/faWineBottle";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faClock } from "@fortawesome/free-regular-svg-icons/faClock";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons/faCalendarAlt";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons/faCalendarDay";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons/faTachometerAlt";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons/faUserFriends";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons/faSlidersH";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons/faCreditCard";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faSun } from "@fortawesome/free-solid-svg-icons/faSun";
import { faMoon } from "@fortawesome/free-solid-svg-icons/faMoon";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons/faHeart";
import { faHeart as fasHeart } from "@fortawesome/free-solid-svg-icons/faHeart";
import { faListAlt } from "@fortawesome/free-regular-svg-icons/faListAlt";
import { faConciergeBell } from "@fortawesome/free-solid-svg-icons/faConciergeBell";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faBorderAll } from "@fortawesome/free-solid-svg-icons/faBorderAll";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons/faQuestionCircle";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faGauge } from "@fortawesome/free-solid-svg-icons/faGauge";
import { faShop } from "@fortawesome/free-solid-svg-icons/faShop";
import { faRectangleList } from "@fortawesome/free-regular-svg-icons/faRectangleList";
import { faBellConcierge } from "@fortawesome/free-solid-svg-icons/faBellConcierge";
import { faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons/faMoneyBillTransfer";
import { faCommentDollar } from "@fortawesome/free-solid-svg-icons/faCommentDollar";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons/faEllipsis";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons/faNewspaper";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons/faRightToBracket";
import { faTag } from "@fortawesome/free-solid-svg-icons/faTag";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons/faArrowCircleLeft";
import { faTools } from "@fortawesome/free-solid-svg-icons/faTools";
import { faFileImport } from "@fortawesome/free-solid-svg-icons/faFileImport";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf";
import { faTasks } from "@fortawesome/free-solid-svg-icons/faTasks";
import { faFlag } from "@fortawesome/free-regular-svg-icons/faFlag";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faPen } from "@fortawesome/free-solid-svg-icons/faPen";
import { faSadTear } from "@fortawesome/free-regular-svg-icons/faSadTear";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons/faPenToSquare";
import { faBan } from "@fortawesome/free-solid-svg-icons/faBan";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons/faCircleCheck";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons/faArrowAltCircleRight";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faChair } from "@fortawesome/free-solid-svg-icons/faChair";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons/faLightbulb";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons/faUserSecret";
import { faCopy } from "@fortawesome/free-regular-svg-icons/faCopy";
import { faCircleCheck as farCircleCheck } from "@fortawesome/free-regular-svg-icons/faCircleCheck";
import { faCheckCircle as farCheckCircle } from "@fortawesome/free-regular-svg-icons/faCheckCircle";
import { faCheckCircle as fasCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons/faUserCircle";
import { faStoreAlt } from "@fortawesome/free-solid-svg-icons/faStoreAlt";
import { faFrownOpen } from "@fortawesome/free-regular-svg-icons/faFrownOpen";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons/faRedoAlt";
import { faMoneyCheck } from "@fortawesome/free-solid-svg-icons/faMoneyCheck";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons/faClipboardCheck";
import { faFish } from "@fortawesome/free-solid-svg-icons/faFish";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { faCreditCard as farCreditCard } from "@fortawesome/free-regular-svg-icons/faCreditCard";
import { faCreditCard as fasCreditCard } from "@fortawesome/free-solid-svg-icons/faCreditCard";
import { faHandshake } from "@fortawesome/free-regular-svg-icons/faHandshake";
import { faStar as fasStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons/faStar";
import { faStarHalfAlt } from "@fortawesome/free-solid-svg-icons/faStarHalfAlt";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons/faStopwatch";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faImage } from "@fortawesome/free-solid-svg-icons/faImage";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons/faThumbtack";
import { faHashtag } from "@fortawesome/free-solid-svg-icons/faHashtag";

library.add(
  faLocationDot,
  faUtensils,
  faUserGroup,
  faAngleDown,
  faArrowCircleRight,
  faMedal,
  faWallet,
  faLanguage,
  faLeaf,
  faWifi,
  faBaby,
  faWineBottle,
  faSearch,
  faClock,
  faCalendarAlt,
  faCalendarDay,
  faTachometerAlt,
  faUser,
  faSignOutAlt,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faSun,
  faMoon,
  fasHeart,
  farHeart,
  faListAlt,
  faConciergeBell,
  faMapMarkerAlt,
  faBorderAll,
  faQuestionCircle,
  faExternalLinkAlt,
  faBellConcierge,
  faMoneyBillTransfer,
  faCommentDollar,
  faEnvelope,
  faTriangleExclamation,
  faEllipsis,
  faMagnifyingGlass,
  faSave,
  faArrowUp,
  faArrowDown,
  faTrashAlt,
  faNewspaper,
  faEdit,
  faRightToBracket,
  faTag,
  faArrowCircleLeft,
  faTools,
  faFileImport,
  faTasks,
  faFlag,
  faCog,
  faBars,
  faHome,
  faPenToSquare,
  faBan,
  faCircleCheck,
  faCirclePlus,
  faArrowAltCircleRight,
  faInfoCircle,
  faExclamationCircle,
  faChair,
  faLightbulb,
  faUserSecret,
  faCopy,
  farCircleCheck,
  farCheckCircle,
  fasCheckCircle,
  faUserCircle,
  faStoreAlt,
  faFrownOpen,
  faRedoAlt,
  faMoneyCheck,
  faClipboardCheck,
  faFish,
  faRectangleList,
  farCircleCheck,
  farCheckCircle,
  fasCheckCircle,
  faGauge,
  faShop,
  faChevronDown,
  faUsers,
  faUserPlus,
  farCreditCard,
  fasCreditCard,
  faHandshake,
  fasStar,
  farStar,
  faStarHalfAlt,
  faStopwatch,
  faTimes,
  faUserFriends,
  faSignInAlt,
  faSlidersH,
  faCreditCard,
  faFilePdf,
  faSadTear,
  faPen,
  faImage,
  faGlobe,
  faThumbtack,
  faHashtag,
);
dom.watch();

// Support component names relative to this directory:
const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
require.context("./images", true);

// https://github.com/reactjs/react-rails/issues/1103
ReactRailsUJS.handleEvent("turbo:load", ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent("turbo:before-render", ReactRailsUJS.handleUnmount);
// ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount)
// ReactRailsUJS.handleEvent('turbo:frame-render', ReactRailsUJS.handleUnmount)
