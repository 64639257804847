import axios from "axios";
import Rails from "rails-ujs";
import React, { useState } from "react";

type Props = {
  isFavorite: boolean | null;
  loggedIn: boolean;
  url: string;
};

const FavoriteButton: React.FC<Props> = (props) => {
  const [isFavorite, setIsFavorite] = useState(props.isFavorite);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick: React.DOMAttributes<HTMLButtonElement>["onClick"] = async (
    event,
  ) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!props.loggedIn) {
      event.currentTarget.form?.submit();
      return;
    }

    try {
      const headers = {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": Rails.csrfToken(),
      };

      if (!isFavorite) {
        await axios.post(props.url, {}, { headers });
        setIsFavorite(true);
      } else if (window.confirm("Are you sure to remove from your list?")) {
        await axios.delete(props.url, { headers });
        setIsFavorite(false);
      }
    } catch (error) {
      console.error(error);
    }

    setIsSubmitting(false);
  };

  return (
    <form action={props.url} method="post">
      <input
        type="hidden"
        name="authenticity_token"
        value={Rails.csrfToken()}
      />
      <button type="submit" disabled={isSubmitting} onClick={handleClick}>
        {isFavorite ? (
          <i className="fa fas-heart" />
        ) : (
          <i className="fa far-heart" />
        )}
      </button>
    </form>
  );
};

export default FavoriteButton;
