import React from "react";

type Props = {
  children: React.ReactNode;
};

export interface FormFieldState {
  isDietaryRestrictionsSelectOther: boolean;
  isDietaryRestrictionsSelectVegan: boolean;
  isSpecialRequestPresent: boolean;
  isRequestChecked: boolean;
  isReadyStripeCard: boolean;
  isFormSending: boolean;
  isUserProfileEntered: boolean;
  dietaryRestrictionsNoRestrictionGuests: number[];
  dietaryRestrictionsAnyCheckedGuests: number[];
  setDietaryRestrictionsVeganGuests: React.Dispatch<
    React.SetStateAction<number[]>
  >;
  setDietaryRestrictionsOtherGuests: React.Dispatch<
    React.SetStateAction<number[]>
  >;
  setDietaryRestrictionsNoRestrictionGuests: React.Dispatch<
    React.SetStateAction<number[]>
  >;
  setDietaryRestrictionsAnyCheckedGuests: React.Dispatch<
    React.SetStateAction<number[]>
  >;
  setIsSpecialRequestPresent: (isSpecialRequestPresent: boolean) => void;
  setIsRequestChecked: (isRequestChecked: boolean) => void;
  setIsReadyStripeCard: (isReadyStripeCard: boolean) => void;
  setIsFormSending: (isFormSending: boolean) => void;
  setIsUserProfileEntered: (isUserProfileEntered: boolean) => void;
}

export const formControlContext: React.Context<FormFieldState> =
  React.createContext<FormFieldState>({
    isDietaryRestrictionsSelectOther: false,
    isDietaryRestrictionsSelectVegan: false,
    isSpecialRequestPresent: false,
    isRequestChecked: false,
    isReadyStripeCard: false,
    isUserProfileEntered: false,
    isFormSending: false,
    dietaryRestrictionsNoRestrictionGuests: [],
    dietaryRestrictionsAnyCheckedGuests: [],
    setDietaryRestrictionsVeganGuests: () => {},
    setDietaryRestrictionsOtherGuests: () => {},
    setDietaryRestrictionsNoRestrictionGuests: () => {},
    setDietaryRestrictionsAnyCheckedGuests: () => {},
    setIsSpecialRequestPresent: () => {},
    setIsRequestChecked: () => {},
    setIsReadyStripeCard: () => {},
    setIsFormSending: () => {},
    setIsUserProfileEntered: () => {},
  });

export const FormFieldStateProvider: React.FC<Props> = ({ children }) => {
  const context: FormFieldState = React.useContext(formControlContext);
  const [dietaryRestrictionsVeganGuests, setDietaryRestrictionsVeganGuests] =
    React.useState<number[]>([]);
  const [dietaryRestrictionsOtherGuests, setDietaryRestrictionsOtherGuests] =
    React.useState<number[]>([]);
  const [
    dietaryRestrictionsNoRestrictionGuests,
    setDietaryRestrictionsNoRestrictionGuests,
  ] = React.useState<number[]>([]);
  const [
    dietaryRestrictionsAnyCheckedGuests,
    setDietaryRestrictionsAnyCheckedGuests,
  ] = React.useState<number[]>([]);
  const [isSpecialRequestPresent, setIsSpecialRequestPresent] = React.useState(
    context.isSpecialRequestPresent,
  );
  const [isRequestChecked, setIsRequestChecked] = React.useState(
    context.isRequestChecked,
  );
  const [isReadyStripeCard, setIsReadyStripeCard] = React.useState(
    context.isReadyStripeCard,
  );
  const [isFormSending, setIsFormSending] = React.useState(
    context.isFormSending,
  );
  const [isUserProfileEntered, setIsUserProfileEntered] = React.useState(
    context.isUserProfileEntered,
  );
  const isDietaryRestrictionsSelectVegan =
    dietaryRestrictionsVeganGuests.length > 0;
  const isDietaryRestrictionsSelectOther =
    dietaryRestrictionsOtherGuests.length > 0;
  const newContext: FormFieldState = {
    isDietaryRestrictionsSelectVegan,
    setDietaryRestrictionsVeganGuests,
    isDietaryRestrictionsSelectOther,
    setDietaryRestrictionsOtherGuests,
    isSpecialRequestPresent,
    setIsSpecialRequestPresent,
    isRequestChecked,
    setIsRequestChecked,
    isReadyStripeCard,
    setIsReadyStripeCard,
    isFormSending,
    setIsFormSending,
    isUserProfileEntered,
    setIsUserProfileEntered,
    dietaryRestrictionsNoRestrictionGuests,
    setDietaryRestrictionsNoRestrictionGuests,
    dietaryRestrictionsAnyCheckedGuests,
    setDietaryRestrictionsAnyCheckedGuests,
  };

  return (
    <formControlContext.Provider value={newContext}>
      {children}
    </formControlContext.Provider>
  );
};
