import xor from "lodash/xor";
import chunk from "lodash/chunk";
import filter from "lodash/filter";
import React, { useState, useRef, useEffect } from "react";
import Rails from "rails-ujs";
import { Modal, Form, Button, Col, Row, Image } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { capitalizeFirstLetter } from "../../utils/strings";
import parse from "html-react-parser";
import axios from "axios";
import { deepCamelToSnake } from "../../utils/convertCase";
import { axiosConfig } from "../../utils/axiosConfig";
import PrefectureBackgroundDefaultImage from "../../../assets/images/top/default_pref.jpg";
import format from "date-fns/format";
import { getDateFnsLocale } from "../../utils/locale";

interface EatineraryProps {
  restaurantCategories: { [key: string]: string };
  restaurantPrefectures: { [key: string]: string };
  submitPath: string;
  eatineraryList: boolean;
  addEatinerary: boolean;
  addEatineraryButton: boolean;
  eatinerary: eatinerary;
}

interface eatinerary {
  id: number;
  prefecture: string;
  prefectureImage: string;
  dateFrom: string;
  dateTo: string;
  categoryNames: [string];
}

const EatinerarySettingFields: React.FC<EatineraryProps> = ({
  restaurantCategories,
  restaurantPrefectures,
  submitPath,
  eatinerary,
  eatineraryList = false,
  addEatinerary = false,
  addEatineraryButton = false,
}) => {
  const [show, setShow] = useState(false);
  const [showListModal, setShowListModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [startDate, setStartDate] = useState(
    eatinerary ? new Date(eatinerary.dateFrom) : null,
  );
  const [endDate, setEndDate] = useState(
    eatinerary ? new Date(eatinerary.dateTo) : null,
  );
  const [categories, setCategories] = useState(
    eatinerary ? eatinerary.categoryNames : [],
  );
  const [prefecture, setPrefecture] = useState(
    eatinerary ? eatinerary.prefecture : "",
  );
  const [eatinaryDraftQueues, setEatinaryDraftQueues] = useState([]);
  const [prefectureImagePathHash, setPrefectureImagePathHash] = useState({});

  const startDatePicker = useRef<DatePicker>();
  const endDatePicker = useRef<DatePicker>();

  const dateAvailability = true;
  const categoryAvailability = true;
  const prefectureAvailability = true;

  const handleStartDateChange = (inputStartDate: Date) => {
    if (!endDate || inputStartDate > endDate) {
      setEndDate(inputStartDate);
    }
    setStartDate(inputStartDate);
  };

  const handleEndDateChange = (inputEndDate: Date) => {
    if (!startDate || inputEndDate < startDate) {
      setStartDate(inputEndDate);
    }
    setEndDate(inputEndDate);
  };

  const prefStyle = (imagePath: string) => {
    return {
      background: `url(${imagePath})  50% 25% no-repeat`,
      backgroundSize: "cover",
    };
  };

  const convertCategoryNames = (categoryNames: [string]): string[] => {
    const convertName = categoryNames.map((name: string) => {
      const result = restaurantCategories.find(
        (item: [string]) => item[0] === name,
      );
      return result ? result[1] : name;
    });
    return convertName;
  };

  const toggleStartDatePicker = () => {
    startDatePicker.current.setOpen(!startDatePicker.current.isCalendarOpen());
  };

  const toggleEndDatePicker = () => {
    endDatePicker.current.setOpen(!endDatePicker.current.isCalendarOpen());
  };

  const dateString = (date: string, locale: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString(locale, options);
  };

  /*
   *  eatinerary の更新処理
   */
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (eatineraryList) {
      const data = {
        prefecture,
        dateFrom: startDate ? format(startDate, "yyyy/MM/dd") : "",
        dateTo: endDate ? format(endDate, "yyyy/MM/dd") : "",
        categoryNames: categories,
      };
      axios
        .patch(
          `${submitPath}/${eatinerary.id}`,
          { eatinerary: deepCamelToSnake(data) },
          axiosConfig,
        )
        .then(() => {
          window.location = "/user/reservations";
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      await pushEatinerary(e);
      setShow(false);
      setShowListModal(true);
      initFormData();
    }
  };

  /*
   *  eatinerary を一括登録するためのデータを追加
   */
  const pushEatinerary = async () => {
    const data = {
      prefecture,
      dateFrom: startDate ? format(startDate, "yyyy/MM/dd") : "",
      dateTo: endDate ? format(endDate, "yyyy/MM/dd") : "",
      categoryNames: categories,
    };
    setEatinaryDraftQueues([...eatinaryDraftQueues, data]);
  };

  /*
   * フォームデータを初期化
   */
  const initFormData = () => {
    setStartDate(eatinerary ? new Date(eatinerary.dateFrom) : null);
    setEndDate(eatinerary ? new Date(eatinerary.dateTo) : null);
    setCategories(eatinerary ? eatinerary.categoryNames : []);
    setPrefecture(eatinerary ? eatinerary.prefecture : "");
  };

  /*
   * 一括登録処理
   */
  const bulkCreateEatinary = async () => {
    eatinaryDraftQueues.forEach(async (data) => {
      await axios.post(
        submitPath,
        { eatinerary: deepCamelToSnake(data) },
        axiosConfig,
      );
    });
    window.location.href = "/user/reservations";
  };

  /*
   * 都道府県別の背景画像を非同期で取得
   */
  const getPrefectureImage = async (prefecture: string) => {
    try {
      const image = await import(
        `../../../assets/images/top/${prefecture}.webp`
      );
      return image.default;
    } catch {
      return PrefectureBackgroundDefaultImage;
    }
  };

  useEffect(() => {
    eatinaryDraftQueues.forEach(async (eatinerary) => {
      const prefecture = eatinerary.prefecture;
      if (prefectureImagePathHash[prefecture]) return;

      const imageUrl = await getPrefectureImage(prefecture);
      setPrefectureImagePathHash({
        ...prefectureImagePathHash,
        [prefecture]: imageUrl,
      });
    });
  }, [eatinaryDraftQueues]);

  return (
    <>
      {/* eatinerary登録ボタン */}
      {addEatineraryButton && (
        <button className="btn btn-primary" onClick={handleShow}>
          {window.i18n.t(
            "components.eatinerary.setting.add_eatinerary_button.button_text",
          )}
        </button>
      )}
      {/* eatinerary登録 */}
      {addEatinerary && (
        <button onClick={handleShow} className="btn btn-primary">
          {window.i18n.t(
            "components.eatinerary.setting.add_eatinerary.button_text",
          )}
        </button>
      )}
      {/* eatineraryリスト表示 */}
      {eatineraryList && (
        <div
          className="c-eatinerary_pref"
          style={prefStyle(eatinerary.prefectureImage)}
        >
          <a className="c-eatinerary_pref_edit" onClick={handleShow}>
            <i className="fa-solid fa-pen"></i>
          </a>
          <div className="c-eatinerary_details">
            <div className="c-titleL">
              {capitalizeFirstLetter(eatinerary.prefecture)}
            </div>
            <div className="c-titleS">{`${dateString(eatinerary.dateFrom, "en")}〜${dateString(eatinerary.dateTo, "en")}`}</div>
            <div className="c-titleS">
              {convertCategoryNames(eatinerary.categoryNames).join(",")}
            </div>
          </div>
        </div>
      )}

      {/* eatinerary登録モーダル */}
      <Modal show={show} onHide={handleClose} size="lg">
        <>
          <Modal.Header closeButton>
            <div className="c-titleM">
              {window.i18n.t("components.eatinerary.setting.modal.title")}
            </div>
          </Modal.Header>
          <Modal.Body>
            <p className="text-center">
              {parse(
                window.i18n.t(
                  "components.eatinerary.setting.modal.description",
                ),
              )}
            </p>

            <Form onSubmit={handleSubmit}>
              {/* 場所 */}
              {prefectureAvailability && (
                <Form.Group className="mb-2" controlId="formGridAddress1">
                  <Form.Label>
                    <b>
                      {window.i18n.t(
                        "components.eatinerary.setting.modal.prefecture_availability.heading",
                      )}
                    </b>
                  </Form.Label>
                  <Form.Group>
                    {restaurantPrefectures.map(([value, name]) => (
                      <div className="c-easyCheck" key={value}>
                        <label>
                          <input
                            type="checkbox"
                            checked={prefecture === value}
                            onChange={() => setPrefecture(value)}
                            value={value}
                            name={"eatinerary[prefecture]"}
                          />
                          <div className="c-easyCheck_content">
                            <div>{name}</div>
                          </div>
                        </label>
                      </div>
                    ))}
                  </Form.Group>
                </Form.Group>
              )}
              {/* 期間 */}
              {dateAvailability && (
                <>
                  <Form.Label>
                    <b>
                      {window.i18n.t(
                        "components.eatinerary.setting.modal.date_availability.heading",
                      )}
                    </b>
                  </Form.Label>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId={"notBeforeFieldName"}>
                      <Form.Label>
                        {window.i18n.t(
                          "components.eatinerary.setting.modal.date_availability.from",
                        )}
                      </Form.Label>
                      <br />
                      <div
                        className="form-control"
                        onClick={toggleStartDatePicker}
                      >
                        <div onClick={(e) => e.stopPropagation()}>
                          <DatePicker
                            locale={getDateFnsLocale()}
                            selected={startDate}
                            onChange={(e) => handleStartDateChange(e)}
                            wrapperClassName="react-datepicker-static"
                            showPopperArrow={false}
                            minDate={new Date()}
                            customInput={
                              <span>
                                <i
                                  className="fas fa-calendar-day"
                                  style={{ marginRight: "10px" }}
                                ></i>
                                {startDate
                                  ? format(startDate, "yyyy/MM/dd")
                                  : "YYYY/MM/DD"}
                                <input
                                  type="hidden"
                                  name="eatinerary[date_from]"
                                  value={
                                    startDate
                                      ? format(startDate, "yyyy/MM/dd")
                                      : ""
                                  }
                                />
                              </span>
                            }
                            ref={(_datePicker: DatePicker) =>
                              (startDatePicker.current = _datePicker)
                            }
                          ></DatePicker>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} controlId={"notAfterFieldName"}>
                      <Form.Label>
                        {window.i18n.t(
                          "components.eatinerary.setting.modal.date_availability.till",
                        )}
                      </Form.Label>
                      <br />
                      <div
                        className="form-control"
                        onClick={toggleEndDatePicker}
                      >
                        <div onClick={(e) => e.stopPropagation()}>
                          <DatePicker
                            locale={getDateFnsLocale()}
                            selected={endDate}
                            onChange={(e) => handleEndDateChange(e)}
                            wrapperClassName="react-datepicker-static"
                            showPopperArrow={false}
                            minDate={new Date()}
                            customInput={
                              <span>
                                <i
                                  className="fas fa-calendar-day"
                                  style={{ marginRight: "10px" }}
                                ></i>
                                {endDate
                                  ? format(endDate, "yyyy/MM/dd")
                                  : "YYYY/MM/DD"}
                                <input
                                  type="hidden"
                                  name="eatinerary[date_to]"
                                  value={
                                    endDate ? format(endDate, "yyyy/MM/dd") : ""
                                  }
                                />
                              </span>
                            }
                            ref={(_datePicker: DatePicker) =>
                              (endDatePicker.current = _datePicker)
                            }
                          ></DatePicker>
                        </div>
                      </div>
                    </Form.Group>
                  </Row>
                </>
              )}
              {/* カテゴリ */}
              {categoryAvailability && (
                <Form.Group className="mb-3" controlId="formGridAddress1">
                  <Form.Label>
                    <b>
                      {window.i18n.t(
                        "components.eatinerary.setting.modal.category_availability.heading",
                      )}
                    </b>
                  </Form.Label>
                  <Form.Group>
                    {
                      //イメージあり
                      chunk(
                        filter(restaurantCategories, (item) => item[2] !== ""),
                        4,
                      ).map((itemList, index) => (
                        <Row key={index} className="row-cols-md-4 row-cols-2">
                          {itemList.map(([key, value, imagePath], no) => (
                            <Col
                              key={no}
                              className="c-eatinerary_prefCheck mb-3"
                            >
                              <label key={key}>
                                <Image src={imagePath} rounded fluid />
                                <input
                                  type="checkbox"
                                  checked={
                                    categories.find((v) => v === key) !=
                                    undefined
                                  }
                                  onChange={() =>
                                    setCategories(xor(categories, [key]))
                                  }
                                  value={key}
                                  name={"eatinerary[category_names][]"}
                                />
                                <div className="c-easyCheck_content">
                                  <div>{value}</div>
                                </div>
                              </label>
                            </Col>
                          ))}
                        </Row>
                      ))
                    }
                    {
                      //イメージなし
                      filter(restaurantCategories, (item) => item[2] == "").map(
                        ([key, value]) => (
                          <div className="c-easyCheck" key={key}>
                            <label>
                              <input
                                type="checkbox"
                                checked={
                                  categories.find((v) => v === key) != undefined
                                }
                                onChange={() =>
                                  setCategories(xor(categories, [key]))
                                }
                                value={key}
                                name={"eatinerary[category_names][]"}
                              />
                              <div className="c-easyCheck_content">
                                <div>{value}</div>
                              </div>
                            </label>
                          </div>
                        ),
                      )
                    }
                  </Form.Group>
                </Form.Group>
              )}
              <div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    !startDate || !endDate || !categories.length || !prefecture
                  }
                >
                  {eatineraryList
                    ? window.i18n.t(
                        "components.eatinerary.setting.modal.submit.button_text.update",
                      )
                    : window.i18n.t(
                        "components.eatinerary.setting.modal.submit.button_text.create",
                      )}
                </button>
              </div>
            </Form>
          </Modal.Body>
        </>
        {/* モーダルフッター */}
        {eatineraryList && (
          <Modal.Footer>
            <Form
              action={`${submitPath}/${eatinerary.id}`}
              method={"post"}
              onSubmit={() => {}}
            >
              <Button variant="danger" type="submit">
                {window.i18n.t(
                  "components.eatinerary.setting.modal.delete.button_text",
                )}
              </Button>
              <input
                type="hidden"
                name="authenticity_token"
                value={Rails.csrfToken()}
              />
              <input type="hidden" name="_method" value="delete" />
            </Form>
          </Modal.Footer>
        )}
      </Modal>

      {/* eatinerary リスト表示 */}
      <Modal
        show={showListModal}
        onHide={() => setShowListModal(false)}
        size="lg"
        className="c-eatinerary_listModal"
      >
        <>
          <Modal.Header closeButton>
            <div className="c-titleM">
              {window.i18n.t("components.eatinerary.setting.modal.title")}
            </div>
          </Modal.Header>
          <Modal.Body>
            {/* eatinerary リスト表示 */}
            {eatinaryDraftQueues.map((eatinerary, index) => (
              <div className="c-eatinerary_pref" key={index}>
                <img
                  className="c-eatinerary_image"
                  src={prefectureImagePathHash[eatinerary.prefecture]}
                  alt={`${eatinerary.prefecture} image`}
                />
                <div className="c-eatinerary_details">
                  <div className="c-titleL">
                    {capitalizeFirstLetter(eatinerary.prefecture)}
                  </div>
                  <div className="c-titleS">{`${dateString(eatinerary.dateFrom, "en")}〜${dateString(eatinerary.dateTo, "en")}`}</div>
                  <div className="c-titleS">
                    {convertCategoryNames(eatinerary.categoryNames).join(",")}
                  </div>
                </div>
              </div>
            ))}
            {/* eatinerary 一括登録 */}
            <div className="c-eatinerary_addBox">
              <a
                className="d-flex align-items-center c-eatinerary_add"
                onClick={() => {
                  setShow(true);
                  setShowListModal(false);
                }}
              >
                <i className="fa-solid fa-circle-plus me-1"></i>
                他の項目を追加する
              </a>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Form onSubmit={bulkCreateEatinary}>
              <button type="submit" className="btn btn-primary">
                {window.i18n.t(
                  "components.eatinerary.setting.modal.submit.button_text.create",
                )}
              </button>
            </Form>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

export default EatinerarySettingFields;
