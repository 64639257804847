import React, { useEffect, useState, useRef, ReactNode } from "react";
import axiosRetry from "axios-retry";
import axios from "axios";
import { format, addDays, isToday } from "date-fns";
import { toJstIsoDate } from "../../utils/dates";
import { SearchResultAvailableOnlineStockGroup } from "../../types/available_online_stock_group";
import { ResponseStatus } from "../../types/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AvailableOnlineStockGroupLoading from "./AvailableOnlineStockGroupLoading";
import cookies from "../../utils/cookies";
import enUS from "date-fns/locale/en-US";
import zhCN from "date-fns/locale/zh-CN";
import zhTW from "date-fns/locale/zh-TW";
import ko from "date-fns/locale/ko";

interface Props {
  restaurant: { slug: string; is_rrs_ready: boolean; is_irs_only: boolean };
  startDate: string;
  endDate: string;
  guestsCount: string;
  mealCategory: string;
  dateChangeable: boolean;
  canJumpToRestaurant: boolean;
}

const AvailableOnlineStockGroups: React.FC<Props> = ({
  restaurant,
  startDate,
  endDate,
  guestsCount,
  mealCategory,
  dateChangeable = false,
  canJumpToRestaurant = false,
}) => {
  // 在庫状況更新間隔
  // 現時点で10分
  const UPDATE_CYCLE = 60 * 1000 * 10;
  let timeoutId = null;
  const [isVisible, setIsVisible] = useState(false);
  const [responseStatus, setResponseStatus] =
    useState<ResponseStatus>("PENDING");
  const [dateFrom, setDateFrom] = useState<string | Date>(startDate);
  const [dateTo, setDateTo] = useState<string | Date>(endDate);
  // 日付、ランチ、ディナー別の配列に入れ直す(table表示のため)
  const [dates, setDates] = useState<string[]>([]);
  const [lunchStocks, setLunchStocks] = useState<
    SearchResultAvailableOnlineStockGroup[]
  >([]);
  const [dinnerStocks, setDinnerStocks] = useState<
    SearchResultAvailableOnlineStockGroup[]
  >([]);
  const componentRef = useRef(null);
  const [dateFnsLocale, setDateFnsLocale] = useState<enUS | zhCN | zhTW | ko>();

  /**
   * クエリパラメータ生成処理
   */
  const buildParameters = () => {
    const params = new URLSearchParams();
    params.append("restaurant_slug", restaurant.slug);
    if (dateFrom) {
      if (dateFrom instanceof Date) {
        params.append("date_from", format(dateFrom, "yyyy-MM-dd"));
      } else {
        params.append("date_from", dateFrom);
      }
    }
    if (dateTo) {
      if (dateTo instanceof Date) {
        params.append("date_to", format(dateTo, "yyyy-MM-dd"));
      } else {
        params.append("date_to", dateTo);
      }
    }
    if (mealCategory) {
      params.append("operation", mealCategory);
    }
    if (guestsCount) {
      params.append("guests_count", guestsCount);
    }

    return params.toString();
  };

  /**
   * 在庫取得処理
   */
  const fetchAvailableOnlineStockGroups = async () => {
    const url = `/restaurants/${restaurant.slug}/available_online_stock_groups?${buildParameters()}`;
    try {
      setResponseStatus("PENDING");

      // このAPIは、画面に表示されているレストラン全てに対してコールされるため、サーバーが「too many requests」を返す場合がある
      // そのため、retry設定をかける
      axiosRetry(axios, {
        retries: 2,
        retryDelay: axiosRetry.exponentialDelay,
      });

      const response =
        await axios.get<SearchResultAvailableOnlineStockGroup[]>(url);
      if (response.status === 200) {
        setDates(response.data.map((datum) => datum.date));
        const lunches = response.data.map((datum) =>
          datum.operation_available_online_stock_groups.find(
            (g) => g.operation == "lunch",
          ),
        );
        const dinners = response.data.map((datum) =>
          datum.operation_available_online_stock_groups.find(
            (g) => g.operation == "dinner",
          ),
        );
        setLunchStocks(lunches);
        setDinnerStocks(dinners);
        setResponseStatus("OK");
        // 再度UPDATE_CYCLEで取得処理を設定する
        timeoutId = setTimeout(() => {
          fetchAvailableOnlineStockGroups();
        }, UPDATE_CYCLE);
      }
    } catch {
      setResponseStatus("ERROR");
    }
  };

  /**
   * マウント時処理
   *
   * 対象の店舗の在庫情報を取得する
   */
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null, // viewportを監視
        rootMargin: "0px",
        threshold: 0.1, // 少なくとも10%が表示されたときに発火
      },
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    setupDateFnsLocale();

    // クリーンアップ
    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    const timeoutValue = Math.floor(Math.random() * 10) * 100;
    // 最初ランダムにAPIアクセスする
    // 処理が集中することを避ける
    timeoutId = setTimeout(() => {
      fetchAvailableOnlineStockGroups();
    }, timeoutValue);

    return () => {
      // アンマウント時にclearしておく
      clearTimeout(timeoutId);
    };
  }, [isVisible, dateFnsLocale, dateFrom]);

  const setupDateFnsLocale = async () => {
    const locale = window.i18n.locale;
    if (locale == "zh-cn") {
      setDateFnsLocale(zhCN);
    } else if (locale == "zh-tw") {
      setDateFnsLocale(zhTW);
    } else if (locale == "ko") {
      setDateFnsLocale(ko);
    } else {
      setDateFnsLocale(enUS);
    }
  };

  /**
   * 日付部分の表示コンポーネント
   * @param date 対象日
   */
  const StockDateComponent: React.FC = ({ prevDate, date }) => {
    const parsedPrevDate = toJstIsoDate(new Date(Date.parse(prevDate)));
    const parsedDate = toJstIsoDate(new Date(Date.parse(date)));
    const weekday = parsedDate.getDay();
    return (
      <div className={weekday == 0 ? "sun" : weekday == 6 ? "sat" : ""}>
        <div>{format(parsedDate, "E", { locale: dateFnsLocale })}</div>
        <div>
          {parsedPrevDate.getMonth() != parsedDate.getMonth()
            ? format(parsedDate, "M/d", { locale: dateFnsLocale })
            : format(parsedDate, "d", { locale: dateFnsLocale })}
        </div>
      </div>
    );
  };

  const setNextWeek = () => {
    const orgStartDate =
      dateFrom instanceof Date ? dateFrom : Date.parse(dateFrom);
    const nextWeekStartDate = addDays(orgStartDate, 7);
    const nextWeekEndDate = addDays(nextWeekStartDate, 6);
    setDateFrom(nextWeekStartDate);
    setDateTo(nextWeekEndDate);
  };

  const setPrevWeek = () => {
    const orgStartDate =
      dateFrom instanceof Date ? dateFrom : Date.parse(dateFrom);
    const nextWeekEndDate = addDays(orgStartDate, -1);
    const nextWeekStartDate = addDays(nextWeekEndDate, -6);
    setDateFrom(nextWeekStartDate);
    setDateTo(nextWeekEndDate);
  };

  /**
   * 枠数クリック時処理
   * @param date クリックされた日
   */
  const handleSeatAvailableClick = (date: string) => {
    // Cookieに入れて店舗ページに遷移
    // 店舗ページで、日付がデフォルトで選択されているようになる。
    cookies.set("search_date", date);
    window.location = `/restaurants/${restaurant.slug}`;
  };

  /**
   * 在庫数、Avail枠表示コンポーネント
   * Clickableでコンポーネントが生成された場合、Aタグで枠数を表示
   * @param date 表示日付
   * @param children 枠数JSX
   */
  const SeatAvailableComponent: React.FC<{
    date: string;
    children: ReactNode;
  }> = ({ date, children }) => {
    return (
      <>
        {canJumpToRestaurant ? (
          <a role={`button`} onClick={() => handleSeatAvailableClick(date)}>
            {children}
          </a>
        ) : (
          <>{children}</>
        )}
      </>
    );
  };

  /**
   * 在庫状況表示コンポーネント
   * @param stock 在庫情報
   */
  const StockComponent: React.FC = ({ stock }) => {
    if (stock) {
      return (
        <>
          {/* リクエスト不可かどうかが一番条件としては強い(枠を作っていても臨時休業などあり得るため) */}
          {stock.is_requestable ? (
            <>
              {/* リクエスト可で、枠がある場合その情報を表示。
               * 枠がない時、枠を使い切っている時の振る舞いは「リクエスト予約枠を利用する」設定に依存
               * 4枠以下は枠数を表示
               */}
              {stock.available_online_stock_groups.length > 0 ? (
                <>
                  {parseInt(stock.aggregated_max_guests_count) > 4 ? (
                    <div className="c-rsvSlotTable_available">
                      <SeatAvailableComponent date={stock.date}>
                        {stock.reservation_type == "instant" && (
                          <>
                            <i className="fa-solid fa-circle-check"></i>
                            <div className="c-rsvSlotTable_available_text">
                              {window.i18n.t(
                                "components.restaurants.available_online_stock_groups.available",
                              )}
                            </div>
                          </>
                        )}
                        {stock.reservation_type == "request" && (
                          <div className="c-rsvSlotTable_request">
                            {window.i18n.t(
                              "components.restaurants.available_online_stock_groups.request",
                            )}
                          </div>
                        )}
                      </SeatAvailableComponent>
                    </div>
                  ) : parseInt(stock.aggregated_max_guests_count) == 0 ? (
                    <>
                      {/* 枠を使い切っている(==0) の時に、rss_readyや、irs_onlyの場合はもうリクエスト受け付けない*/}
                      {restaurant.is_rrs_ready || restaurant.is_irs_only ? (
                        <div className="c-rsvSlotTable_unavailable">
                          {window.i18n.t(
                            "components.restaurants.available_online_stock_groups.n_a",
                          )}
                        </div>
                      ) : (
                        <div className="c-rsvSlotTable_request">
                          {window.i18n.t(
                            "components.restaurants.available_online_stock_groups.request",
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="c-rsvSlotTable_available">
                      <SeatAvailableComponent date={stock.date}>
                        {stock.reservation_type == "instant" && (
                          <>
                            <i className="fa-solid fa-circle-check"></i>
                            <div className="c-rsvSlotTable_available_text">
                              {window.i18n.t(
                                "components.restaurants.available_online_stock_groups.seats_left",
                                { num: stock.aggregated_max_guests_count },
                              )}
                            </div>
                          </>
                        )}
                        {stock.reservation_type == "request" && (
                          <div className="c-rsvSlotTable_request">
                            {window.i18n.t(
                              "components.restaurants.available_online_stock_groups.request",
                            )}
                          </div>
                        )}
                      </SeatAvailableComponent>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {/* 枠を使い切っている(==0) の時に、rss_readyや、irs_onlyの場合はもうリクエスト受け付けない*/}
                  {restaurant.is_rrs_ready || restaurant.is_irs_only ? (
                    <div className="c-rsvSlotTable_unavailable">
                      {window.i18n.t(
                        "components.restaurants.available_online_stock_groups.n_a",
                      )}
                    </div>
                  ) : (
                    <div className="c-rsvSlotTable_request">
                      {window.i18n.t(
                        "components.restaurants.available_online_stock_groups.request",
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="c-rsvSlotTable_unavailable">
              {window.i18n.t(
                "components.restaurants.available_online_stock_groups.unavailable",
              )}
            </div>
          )}
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div ref={componentRef}>
        {dates.length > 0 && responseStatus == "OK" && (
          <>
            {dateChangeable && (
              <div className={"mb-2 d-flex justify-content-between"}>
                <div>
                  {!isToday(
                    dateFrom instanceof Date ? dateFrom : Date.parse(dateFrom),
                  ) && (
                    <a
                      className="btn btn-light btn-sm"
                      href={"javascript:void(0)"}
                      onClick={(e) => {
                        setPrevWeek();
                        e.preventDefault();
                      }}
                    >
                      <i className="fa-solid fa-chevron-left"></i>
                    </a>
                  )}
                </div>
                <div>
                  <a
                    className="btn btn-light btn-sm"
                    href={"javascript:void(0)"}
                    onClick={(e) => {
                      setNextWeek();
                      e.preventDefault();
                    }}
                  >
                    <i className="fa-solid fa-chevron-right"></i>
                  </a>
                </div>
              </div>
            )}
            <table className={"c-rsvSlotTable table"}>
              <tbody>
                <tr>
                  <th>{window.i18n.t("date")}</th>
                  {dates.map((date, index) => (
                    <td key={`stock-date-${index}`} className={"text-center"}>
                      <StockDateComponent
                        prevDate={index > 0 ? dates[index - 1] : date}
                        date={date}
                      />
                    </td>
                  ))}
                </tr>
                {lunchStocks.filter((n) => n).length > 0 && (
                  <tr>
                    <th>
                      <span className={"me-1"}>
                        <FontAwesomeIcon
                          icon={"sun"}
                          style={{ color: "#F99B45" }}
                        />
                      </span>
                      {window.i18n.t("meal_categories.lunch")}
                    </th>
                    {lunchStocks.map((lunchStock, index) => (
                      <td
                        key={`stock-lunch-${index}`}
                        className={"c-rsvSlotTable_availability"}
                      >
                        <StockComponent stock={lunchStock}></StockComponent>
                      </td>
                    ))}
                  </tr>
                )}
                {dinnerStocks.filter((n) => n).length > 0 && (
                  <tr>
                    <th>
                      <span className={"me-1"}>
                        <FontAwesomeIcon
                          icon={"moon"}
                          style={{ color: "#606AC6" }}
                        />
                      </span>
                      {window.i18n.t("meal_categories.dinner")}
                    </th>
                    {dinnerStocks.map((dinnerStock, index) => (
                      <td
                        key={`stock-dinner-${index}`}
                        className={"c-rsvSlotTable_availability text-center"}
                      >
                        <StockComponent stock={dinnerStock}></StockComponent>
                      </td>
                    ))}
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
        {responseStatus == "PENDING" && (
          <>
            {" "}
            <AvailableOnlineStockGroupLoading />{" "}
          </>
        )}
      </div>
    </>
  );
};

export default AvailableOnlineStockGroups;
