import axios from "axios";
import React, { useEffect, useState } from "react";
import MakerMap, { MakerLatLng } from "../../common/MakerMap";
import { ResponseStatus } from "../../types/common";

interface Props {
  makerClicked: (restaurantId: number) => void;
  mapKey: string;
  showDataWithoutHover: boolean;
}

/**
 * レストランの地図表示コンポーネント
 */
const RestaurantMapContent: React.FC<Props> = ({
  makerClicked,
  mapKey,
  showDataWithoutHover = true,
}) => {
  const [responseStatus, setResponseStatus] = useState<ResponseStatus>("OK");
  const [slugs, setSlugs] = useState<string[]>([]);
  const [latLngList, setLatLngList] = useState<MakerLatLng[]>([]);

  /**
   * 記事に表示されている店舗のスラグから地理上を取得し緯度経度を設定
   */
  const setLatLng = async () => {
    try {
      setResponseStatus("PENDING");

      const url = `/restaurant_geo_locations?${slugs.map((slug) => "slugs[]=" + slug).join("&")}`;
      const response = await axios.get(url);
      const geoLocations = response.data;

      const newGeoLocations = geoLocations.map((geoLocation) => {
        return {
          restaurantId: geoLocation.id,
          slug: geoLocation.slug,
          restaurantName: geoLocation.restaurant_name,
          lat: geoLocation.latitude,
          lng: geoLocation.longitude,
          operationHours: geoLocation.operation_hours,
          holidays: geoLocation.holidays,
        };
      });

      setLatLngList((prevState) => [...prevState, ...newGeoLocations]);
      setResponseStatus("OK");
    } catch {
      setResponseStatus("ERROR");
    }
  };

  /**
   * 記事内の店舗スラグを取得
   * 記事のHTML構成ルールとして、店舗情報にはp-articleShow_restaurantInfoクラスを付与している
   * また、データ属性としてrestaurantSlugが設定されている
   */
  const collectSlugsInArticle = () => {
    const restaurantInfo = document.querySelectorAll(
      ".p-articleShow_restaurantInfo",
    );

    // slugだけに変換
    const filteredSlugs = Array.from(restaurantInfo)
      .filter((info) => info.dataset.restaurantSlug)
      .map((info) => info.dataset.restaurantSlug);

    setSlugs(filteredSlugs);
  };

  /**
   * 外部店舗の井戸経度取得
   * 記事のHTML構成ルールとして、外部店舗にはデータ属性に緯度経度、スラグとしてはハッシュ値が設定されている
   */
  const collectLatLangInArticle = () => {
    const restaurantInfo = document.querySelectorAll(
      ".p-articleShow_restaurantInfo",
    );
    const filteredLatLngList = Array.from(restaurantInfo)
      .filter((info) => info.dataset.latitude && info.dataset.longitude)
      .map((info) => {
        return {
          restaurantId: info.dataset.restaurantId,
          slug: info.dataset.hashValue,
          restaurantName: info.dataset.restaurantName,
          lat: parseFloat(info.dataset.latitude),
          lng: parseFloat(info.dataset.longitude),
          operationHours: info.dataset.operationHours,
          holidays: info.dataset.regularHoliday,
        };
      });
    setLatLngList((prevState) => [...prevState, ...filteredLatLngList]);
  };

  /**
   * マウント時処理
   */
  useEffect(() => {
    collectSlugsInArticle();
    collectLatLangInArticle();
  }, []);

  /**
   * スラグ変更時監視
   */
  useEffect(() => {
    if (slugs.length > 0) {
      setLatLng();
    }
  }, [slugs]);

  return (
    <div>
      {responseStatus === "OK" && (
        <MakerMap
          latLangList={latLngList}
          makerClicked={makerClicked}
          mapKey={mapKey}
          showDataWithoutHover={showDataWithoutHover}
        />
      )}
      {responseStatus === "ERROR" && <>地図の取得に失敗しました。</>}
    </div>
  );
};

export default RestaurantMapContent;
