import React, { useState } from "react";
import RestaurantMapContent from "./RestaurantMapContent";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

interface Props {
  mapKey: string;
}

/**
 * 全画面のレストランの地図表示コンポーネント
 */
const FullScreenRestaurantMap: React.FC<Props> = ({ mapKey }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const markerClicked = (/*restaurantId: number*/) => {
    setShow(false);
  };

  return (
    <div className={"p-articleShow_mapSP_wrap"}>
      <Button
        variant="btn btn-lg btn-basic btn-round"
        className="p-search_mapOpenButton"
        onClick={handleShow}
      >
        <i className="fa-solid fa-location-dot"></i>
      </Button>

      <Offcanvas
        className={"articleShow_mapSP_offcanvas"}
        show={show}
        onHide={handleClose}
        responsive="lg"
        placement={"end"}
        style={{ width: "100%" }}
      >
        <Offcanvas.Header closeButton />
        <Offcanvas.Body>
          <RestaurantMapContent makerClicked={markerClicked} mapKey={mapKey} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default FullScreenRestaurantMap;
